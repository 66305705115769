
import "./modal-sidebar-v1"
import "./cart-review"
import { CF2Component } from 'javascript/lander/runtime'

export default class CartReviewModal extends CF2Component {

constructor(el, runtimeSel) {
super(el, runtimeSel)
}

mount() {
      const modal = this.getComponent("ModalSidebar/V1")
      $(document).on('click', 'a[href="#open-cart"]', () => {
        modal.show()
      })
      $(document).on('click', 'a[href="#close-cart"]', () => {
        modal.hide()
      })

      if (window.location.hash == '#open-cart') {
        modal.show()
      }
    }



}

window["CartReviewModal"] = CartReviewModal

